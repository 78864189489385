<template>
  <div>
    <div v-if="isRegistered">
      <base-card>
        <h3>
          Your property has been successfully registered, Please wait for a
          confirmation from admin.
        </h3>
        <button @click="registerAnotherProperty" class="btn btn-success">
          Register another property
        </button>
      </base-card>
    </div>
    <div v-if="isLoading">
      <base-card>
        <base-spinner></base-spinner>
        <p>PLease wait a moment for your property to be registered.</p>
      </base-card>
    </div>
    <base-card v-if="!isRegistered && !isLoading" class="container">
      <form @submit.prevent="rentalRegister">
        <div class="form-group">
          <label for="buildingName">Building Name</label>
          <input
            type="text"
            class="form-control"
            id="buildingName"
            v-model.trim="buildingName"
          />
        </div>
        <div class="form-group">
          <label for="apartmentNumber">Apartment Number</label>
          <input
            type="number"
            class="form-control"
            id="apartmentNumber"
            v-model.trim="apartmentNumber"
          />
        </div>
        <div class="form-group">
          <label for="location">Location</label>
          <select class="form-control" id="location" v-model="location">
            <option value="Bakuli">Bakuli</option>
            <option value="Bugoloobi">Bugoloobi</option>
            <option value="Bukoto">Bukoto</option>
            <option value="Busega">Busega</option>
            <option value="Butabika">Butabika</option>
            <option value="Butikiro">Butikiro</option>
            <option value="Bwaise">Bwaise</option>
            <option value="Ggaba">Ggaba</option>
            <option value="Kabalagala">Kabalagala</option>
            <option value="Kabowa">Kabowa</option>
            <option value="Kaleerwe">Kaleerwe</option>
            <option value="Kampala Hill">Kampala Hill</option>
            <option value="Kamwookya">Kamwookya</option>
            <option value="Kasanga">Kasanga</option>
            <option value="Kanyanya">Kanyanya</option>
            <option value="Kasubi hill">Kasubi hill</option>
            <option value="Katwe">Katwe</option>
            <option value="Kawaala">Kawaala</option>
            <option value="Kawempe">Kawempe</option>
            <option value="Kibuli">Kibuli</option>
            <option value="Kibuye, Uganda">Kibuye, Uganda</option>
            <option value="Kigoowa">Kigoowa</option>
            <option value="Kikaaya">Kikaaya</option>
            <option value="Kisaasi">Kisaasi</option>
            <option value="Kisenyi">Kisenyi</option>
            <option value="Kololo">Kololo</option>
            <option value="Kulambiro">Kulambiro</option>
            <option value="Kyebando">Kyebando</option>
            <option value="Lubaga">Lubaga</option>
            <option value="Lugogo, Kampala">Lugogo, Kampala</option>
            <option value="Lungujja">Lungujja</option>
            <option value="Luzira">Luzira</option>
            <option value="Makerere">Makerere</option>
            <option value="Makerere Kikoni">Makerere Kikoni</option>
            <option value="Makindye">Makindye</option>
            <option value="Mengo">Mengo</option>
            <option value="Mpererwe">Mpererwe</option>
            <option value="Mulago">Mulago</option>
            <option value="Munyonyo">Munyonyo</option>
            <option value="Muyenga">Muyenga</option>
            <option value="Naakulabye">Naakulabye</option>
            <option value="Nakasero">Nakasero</option>
            <option value="Nakawa">Nakawa</option>
            <option value="Namirembe Hill">Namirembe Hill</option>
            <option value="Namungoona">Namungoona</option>
            <option value="Namuwongo">Namuwongo</option>
            <option value="Nateete">Nateete</option>
            <option value="Ndeeba">Ndeeba</option>
            <option value="Nsambya">Nsambya</option>
            <option value="Ntinda">Ntinda</option>
            <option value="Old Kampala">Old Kampala</option>
            <option value="Port Bell">Port Bell</option>
            <option value="Wandegeya">Wandegeya</option>
          </select>
        </div>

        <div class="form-group">
          <label for="image-url">Image Url</label>
          <input
            type="file"
            class="form-control"
            id="image-url"
            accept="image/*"
            ref="fileInput"
            @change="onFilePicked"
            multiple
          />
        </div>
        <div class="viewImages">
          <img :src="imageUrl" alt="" class="d-block w-auto h-0.1" />
        </div>
        <div class="form-group">
          <label for="price">Price</label>
          <input
            type="number"
            class="form-control price"
            id="price"
            v-model="price"
            placeholder="2,000,000"
          /><span> UGX</span>
        </div>

        <div class="form-group">
          <label for="bedrooms">Enter the Number of bedrooms</label>
          <input
            type="number"
            v-model.trim="numberOfBedrooms"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="bathrooms">Enter the Number of bathrooms</label>
          <input
            type="number"
            v-model.trim="numberOfBathrooms"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="sittingRoom">Enter the Number of SittingRooms</label>
          <input
            type="number"
            v-model.trim="sittingRoom"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="contactPersonName">Contact Person Name</label>
          <input
            type="text"
            class="form-control"
            id="contactPersonName"
            v-model.trim="contactPersonName"
          />
        </div>

        <div class="form-group">
          <label for="contactPersonName">Contact Person Phone</label>
          <input
            type="tel"
            placeholder="+256..."
            class="form-control"
            id="contactPersonPhone"
            v-model.trim="contactPersonPhone"
          />
        </div>

        <div class="form-group">
          <label for="description"
            >Add a description -
            <span class="text-muted">(optional)</span></label
          >
          <textarea
            name="description"
            id="description"
            rows="5"
            class="form-control"
            v-model="description"
          ></textarea>
        </div>
        <p v-if="!formIsValid" style="color: red">
          Please make sure you provided all the required information!!
        </p>
        <button class="btn btn-primary">Submit</button>
      </form>
    </base-card>
  </div>
</template>

<script>
import Compressor from "compressorjs";
export default {
  data() {
    return {
      numberOfBathrooms: null,
      numberOfBedrooms: null,
      imageUrl: [],
      location: "Kasanga",
      price: null,
      image: [],
      isLoading: false,
      description: "",
      buildingName: "",
      apartmentNumber: null,
      sittingRoom: null,
      contactPersonName: "",
      contactPersonPhone: "",
      formIsValid: true,
      isRegistered: false,
    };
  },
  methods: {
    validateForm() {
      this.formIsValid = true;
      if (this.price === null) {
        this.formIsValid = false;
      }
      if (this.imageUrl.length <= 0) {
        this.formIsValid = false;
      }
      if (this.numberOfBathrooms === null) {
        this.formIsValid = false;
      }
      if (this.numberOfBedrooms === null) {
        this.formIsValid = false;
      }
      if (this.apartmentNumber === null) {
        this.formIsValid = false;
      }
      if (this.buildingName === "") {
        this.formIsValid = false;
      }
    },

    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a value");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      // console.log("The whole files", files)
      // console.log("This is the image url", this.imageUrl);
      let compressedImages = [];
      for (let file of files) {
        // console.log(file, "original")
        new Compressor(file, {
          quality: 0.4,
          success(result) {
            const myFile = new File([result], result.name, {
              type: result.type,
            });
            // console.log("compressed:", myFile)
            compressedImages.push(myFile);
          },
        });
      }
      this.image = compressedImages;
      // console.log("Hello", this.image);
    },
    async rentalRegister() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      const rental = {
        buildingName: this.buildingName,
        apartmentNumber: this.apartmentNumber,
        numberOfBathrooms: this.numberOfBathrooms,
        numberOfBedrooms: this.numberOfBedrooms,
        description: this.description,
        location: this.location,
        price: this.price,
        sittingRoom: this.sittingRoom,
        image: this.image,
        approved: false,
        booked: false,
        contactPersonName: this.contactPersonName,
        contactPersonPhone: this.contactPersonPhone
      };
      this.isLoading = true;

      await this.$store.dispatch("rentals/addRental", rental);

      (this.numberOfBathrooms = null),
        (this.numberOfBedrooms = null),
        (this.buildingName = ""),
        (this.apartmentNumber = null),
        (this.image = []),
        (this.description = ""),
        (this.price = null),
        (this.isLoading = false),
        (this.location = "");
        this.contactPersonName = "",
        this.contactPersonPhone = ""

      // this.$router.replace("/rentals");
      this.isLoading = false;
      this.isRegistered = true;
    },
    registerAnotherProperty() {
      this.isRegistered = false;
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.viewImages {
  height: 100px;
}
.viewImages img {
  float: left;
  width: 100px;
  height: 100px;
  /* object-fit: cover; */
}
label {
  display: block;
}
#price {
  width: 80%;
  display: inline;
}
#price > span {
  padding: 5px;
}
</style>